<template>
  <div id="app">
    <Header/>
  <div class="maincontent">
    <router-view></router-view>
  </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './components/Footer_and_Header/Footer.vue';
import Header from './components/Footer_and_Header/Header.vue';

export default {
  components: {
    Header,
    Footer
  },
};
</script>

<style>
.maincontent {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align all items horizontally */
  justify-content: flex-start; /* Align items at the top */
  overflow: hidden; /* Hide any overflowing content */
  width: 100vw; /* Ensure it takes the full viewport width */
  flex: 1;
  margin-top: 5vh;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure it takes the full viewport height */
  width: 100vw; /* Ensure it takes the full viewport width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

@media (max-width: 900px) {
  .maincontent {

  margin-top: 1vh;
}
}
</style>
