<template>
  <div class="header">
    <router-link to="/">
      <img src="@/assets/logo.svg" class="logo-img" alt="Website Logo">
    </router-link>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.header { 
  width: 100vw;
  display: flex;
  justify-content: left;
}

.logo-img {
  padding: 1vw 0px 0px 1vw;
  height: auto;
  width: 18vw;
}

/* Media Query for devices with a max-width of 900px (adjust as needed) */
@media (max-width: 900px) {
  .logo-img {
    width: 50vw; /* Fixed size for smaller screens */
    padding: 10px; /* Adjust padding as needed */
  }
}
</style>
