import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    meta: {
      title: 'Mainpage - HeightByCountry',
      description: 'Comparison of average heights for women and men of different countries'
    }},
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/Footer/Imprint.vue'),
    meta: {
      title: 'Imprint - HeightByCountry',
      description: 'This is the imprint page of HeightByCountry.com'
    }
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/Footer/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy - HeightByCountry',
      description: 'This is the privacy policy page of HeightByCountry.com'
    }
  },
  {
    path: '/termsofuse',
    name: 'Terms of Use',
    component: () => import(/* webpackChunkName: "termsofuse" */ '../views/Footer/TermsOfUse.vue'),
    meta: {
      title: 'Terms of Use - HeightByCountry',
      description: 'This is the terms of use page of HeightByCountry.com'
    }
  },
  {
    path: '/dataoverview',
    name: 'Data Overview',
    component: () => import(/* webpackChunkName: "dataoverview" */ '../views/Footer/DataOverview.vue'),
    meta: {
      title: 'Dataoverview - HeightByCountry',
      description: 'Table data for Height Comparison'
    }
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

export default router